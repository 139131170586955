import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { accordionData } from './accordionData.js'


const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '8px !important', 
  border: '0',
  boxShadow: 'none',
  overflow: 'hidden',
  backgroundColor: '#FFFFFF33',
  marginTop: '0 !important',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
  gap: '20px',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
    width: '1.5em',
    height: '1.5em',
  },
  '& svg': {
      width: '1.5em',
      height: '1.5em',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: '0.5px solid #ccc',
  color: 'white',
  fontSize: '16px',
  fontFamily: '"Red Hat Display", sans-serif',
  fontWeight: 400,
  padding: '16px 24px',
}));

const sxPropSummary = {
  padding: '12px 24px',
  minHeight: 68,
  '&.Mui-expanded': {
    minHeight: 60,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: '"Red Hat Display", sans-serif',
  fontWeight: '700',
  color: '#FFFFFF'
}));

export default function AccordionUsage() {
  return (
    <>
      <h1>F.A.Q.</h1>
      <div>
        {accordionData.map((accordion, index) => (
          <StyledAccordion key={index}>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={sxPropSummary}
            >
              <StyledTypography component="span">{accordion.summary}</StyledTypography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <p dangerouslySetInnerHTML={{ __html: accordion.details }}/>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </>
  );
}
