import React from "react";
import styled from "styled-components";
import "../components/font-awesome";
import AccordionUsage from "../components/faqs/accordions";
import AccordionContactBlock from "../components/faqs/contactBlock";

const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background: var(--gradient-bg);
   color: var(--text-inverted);
   flex-grow: 1;

   .innerContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      max-width: var(--screen-max-width);
      margin: var(--screen-margin);
      padding: calc(100px + 64px) 0;
   }

   .comparison-table-wrapper{
      width: 100%;
      overflow-x: auto;
   }
   .comparison-table {
      margin: 16px 0;
      border-spacing: 12px; 
      border-collapse: separate; 
   }
   
   .comparison-table td,
   .comparison-table th {
      text-align: left;
   }


   @media only screen and (max-width: 600px) {
      .innerContainer.faqsInnerContainer {
         padding: 100px 0;
      }
   }
   @media only screen and (max-width: 510px) {
      .innerContainer.faqsInnerContainer {
         width: calc(100vw - 50px);
      }
   }
`;

export default function FaqsTemplate() {
   return (
      <Container>
         <div className="innerContainer faqsInnerContainer">
         <AccordionUsage/>
         <AccordionContactBlock/>
         </div>
      </Container>
   );
}
