export const accordionData = [
    {
        summary: 'Your prices seem low. How can you guarantee quality at that price?',
        details: "You're right, our prices might seem low compared to what you're used to, but we have worked diligent and hard so that we can provide you with those prices. See them as a testimony to our optimization efforts.In terms of quality, at <a style='color:white' href='http://nearshore.pt/'>Nearshore.pt</a>, we're proud to have access to some of the brightest minds in the Portuguese tech scene, and we're confident we can find the perfect match for your needs right here."
    },
    {
        summary: 'How much can I realistically save by Nearshoring with you compared to my current costs?',
        details: `
          <p>That's a great question, and the answer depends on your specific needs and current costs. To estimate your potential savings based on your specific needs, we encourage you to use our free HireWise Calculator. It's a quick and easy way to explore different scenarios and see how Nearshore.pt can benefit your bottom line.</p>
          <p>To make it easier to understand, let's explore two common scenarios with a visual comparison:</p>
          <div class="comparison-table-wrapper">
          <table class="comparison-table">
            <thead>
            <tr>
                <th><strong>Feature</strong></th>
                <th><strong>Freelance Developer (€90/hr)</strong></th>
                <th><strong>Local Senior Developer (€90k-€120k/yr)</strong></th>
                <th><strong>Nearshore.pt</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><strong>Hourly Rate</strong></td>
                <td>€90</td>
                <td>-</td>
                <td class="highlight">Starting at €30</td>
            </tr>
            <tr>
                <td><strong>Weekly Hours</strong></td>
                <td>32</td>
                <td>40</td>
                <td>Flexible</td>
            </tr>
            <tr>
                <td><strong>Recruitment Costs</strong></td>
                <td>€0</td>
                <td>€30,000</td>
                <td>€0</td>
            </tr>
            <tr>
                <td><strong>Annual Cost</strong></td>
                <td>€138,240</td>
                <td>€90,000 - €120,000+</td>
                <td class="highlight">Potentially &lt; €70,000</td>
            </tr>
            <tr>
                <td><strong>Flexibility</strong></td>
                <td>Limited</td>
                <td>Limited</td>
                <td>High</td>
            </tr>
            <tr>
                <td><strong>Access to Talent</strong></td>
                <td>Varies</td>
                <td>Limited by local market</td>
                <td>Wide pool of Portuguese talent</td>
            </tr>
            <tr>
                <td><strong>Risk</strong></td>
                <td>Low</td>
                <td>High</td>
                <td>Medium</td>
            </tr>
            <tr>
                <td class="highlight"><strong>TOTAL</strong></td>
                <td class="highlight">€138,240</td>
                <td class="highlight">€120,000 - €150,000+</td>
                <td class="highlight">Potentially &lt; €70,000</td>
            </tr>
            </tbody>
        </table>
        </div>
          <p>As you can see. In addition to cost savings, you gain access to a wide pool of highly skilled developers in Portugal, known for its strong technical expertise and excellent English proficiency.</p>
          <p>We offer flexible contracts, allowing you to scale your team up or down as needed and only pay for the resources you require. Our rigorous technical assessments and comprehensive onboarding process minimize the risk of costly mismatches and ensure a smooth integration of new developers.</p>
        `,
      },
    {
        summary: 'Our projects often require specialized expertise. Can you provide developers with niche skills, or are you focused on more common technologies?',
        details: 'We understand the importance of specialized expertise for your projects.  We maintain a strong network of developers proficient in common technologies, but we also specialize in sourcing and vetting talent with niche skills. As a bonus, all our recruitment efforts are included in the current price, so you can access top talent without any hidden fees or surprises.',
    },
    {
        summary: "We're concerned about IP protection. What measures do you have in place to safeguard our intellectual property?",
        details: `We understand that protecting your intellectual property (IP) is paramount. 

                    To safeguard your sensitive information, we implement comprehensive measures, including strict Non-Disclosure Agreements (NDAs) for all developers and contracts.
                    <ul>
                    <li> We adhere to all relevant data protection and IP laws in both our jurisdiction and yours.
                    <li> Our developers work in secure environments with controlled access to your IP, utilizing robust encryption and access control measures.</li>
                    <li> Your code and sensitive data are stored in secure repositories with strict access controls and audit trails.</li>
                    <li> We conduct thorough background checks on all developers and provide regular training on IP protection and data security best practices. Our dedicated security team monitors our systems and processes to identify and mitigate potential risks.</li>
                    <li> We maintain open communication with you regarding our security measures and conduct regular audits to ensure robustness.</li>
                    </ul>`,
    },
    {
        summary: "We've had bad experiences with nearshore before. How do you ensure effective communication and collaboration with remote teams?",
        details: `At <a style="color:white" href="http://nearshore.pt/">Nearshore.pt</a>, we have a profound understanding of the challenges and best practices for successful remote work.

In fact, Caixa Mágica, our parent company, has 100 remote workers, which have honed our processes over the years. We utilize various tools and techniques, including daily stand-up meetings, regular video conferences, instant messaging platforms, and project management tools.`,
    },
    {
        summary: 'What is the typical contract length? Can we scale the team up or down as our needs change? ',
        details: `Nearshore has a typical contract length is around six months to a year for larger projects. However, we understand that your needs may vary.  

We therefore offer contracts starting at just one month, ideal for short-term projects or those with fluctuating demands. Additionally, we provide the option to engage developers on a part-time basis, allowing you to access specialized skills and scale your team precisely to your needs.`,
    },
    {
        summary: "The 'HireWise Calculator' seems useful. Can it provide a detailed breakdown of costs, including any potential hidden fees?",
        details: `The HireWise Calculator is designed to be completely transparent and provide a detailed cost breakdown, eliminating any concerns about hidden fees. It's also free to use, so anyone can explore different scenarios and make informed decisions about their hiring strategy.`,
    },
    {
        summary: "My company works with clients outside of Portugal, could my location be a problem?",
        details: `Not a problem at all! While our talent pool is currently concentrated in Portugal, we work with clients across Europe and beyond.  We've designed our services to be location-agnostic, leveraging the remote first approach. We strive for technology and effective communication strategies to bridge geographical gaps.`,
    },
    {
        summary: 'Is my company to big or too small for an extended team? ',
        details: `Nearshore.pt is designed to be flexible and adaptable to companies of various sizes. Whether you're a small startup with a handful of employees or a large enterprise with hundreds of team members, we can tailor our services to meet your specific needs.

                    For <strong>smaller companies</strong>,  an extended team can provide access to specialized skills and resources that might be otherwise unavailable or too costly to acquire in-house. 
                    For <strong>larger companies</strong>, an extended team can offer increased flexibility and scalability, allowing you to quickly adapt to changing project demands and optimize your workforce.`,
                        },
    {
        summary: "Can you explain more about the 'Technical Assessment' stage in your recruitment process? What does that involve for us? ( use it as content, not as FAQ) CTA contact us.",
        details: `
                    We're glad you're interested in our Technical Assessment stage! It's a crucial step in ensuring we find the perfect IT professional for your needs. In essence, the Technical Assessment stage provides you with a clear and objective evaluation of the candidate's skills, ensuring a strong fit for your project and minimizing the risk of costly mismatches.

                    Here's what it involves for you:
                        <ul>        
                            <li> Minimal involvement, maximum insight: We understand your time is valuable. That's why we've designed the Technical Assessment to be as streamlined as possible for you.  We handle the heavy lifting, while you gain valuable insights into the candidate's abilities.
                            <li> Collaboration and transparency: We work closely with you to define the assessment criteria and the specific technologies or frameworks to be evaluated. You'll have full visibility into the assessment process and the opportunity to provide input at every stage.
                            <li> Comprehensive evaluation: Our Technical Assessments go beyond simple coding tests. We evaluate a range of skills, including:
                                <ul>
                                    <li> Technical proficiency: Assessing the candidate's knowledge and expertise in the required technologies.
                                    <li> Problem-solving abilities: Evaluating their ability to analyze problems, design solutions, and write clean, efficient code.
                                    <li> Communication and collaboration: Assessing their ability to effectively communicate technical concepts and work collaboratively in a team environment.
                                    <li> Detailed feedback and reporting: You'll receive a comprehensive report on the candidate's performance, including detailed feedback on their strengths and areas for improvement. This allows you to make informed decisions with confidence.
                                </ul>
                        </ul>`,
    },
  ];