import "../components/font-awesome";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import Footer from "../components/homePage/footer";
import FaqsTemplate from "../templates/faqs";

const SeoWrapper = styled.div``;

const FaqsPage = () => {
   return (
         <SeoWrapper>
            <Seo title={"F.A.Q."} />
            <Layout>
               <FaqsTemplate/>
               <Footer/>
            </Layout>    
         </SeoWrapper>
   );
};

export default FaqsPage;