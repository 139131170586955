import * as React from "react";
import Button, { ButtonTypes } from "../atoms/button";
import styled from "styled-components";


const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background: #F2F2F8;
   border-radius: 16px;
   padding: 64px;
   margin-top: 28px;

   .contactCardInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      color: #000000;
   }

   .contactCardHeader {
      display: flex;
      flex-direction: column;
      font-size: 21px;
   }

   @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 32px;
      padding: 32px 20px;
        .contactCardInfo {
          gap: 20px;
        }
   }
`;


export default function AccordionContactBlock() {
    return (
      <Container>
        <div className="contactCardInfo">
          <h3 className="contactCardHeader">
            <span>Don't find the answer to your question?</span>
            <span>We're happy to answer any questions you might have.</span>
          </h3>
          <p>If you're still confused over our technical assessment stage and how that involves you, leave us a message!</p>
        </div>
        <div>
        <Button
          content="Contact Us"
          type={ButtonTypes.PRIMARY}
          url={"/contact-us"}
        />
        </div>
        </Container>
        );
    
}